<template>
  <div>
    <div class="search">
      <el-row>
        <el-col :span="4">
          <el-select
            class="input"
            size="small"
            v-model="search.job"
            :placeholder="$t('allJob')"
            clearable
          >
            <el-option
              v-for="(item, index) in jobList"
              :key="index"
              :label="item.name"
              :value="item.pisition_id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select
            class="input"
            size="small"
            v-model="search.status"
            :placeholder="$t('resumeStatus')"
            clearable
          >
            <el-option
              v-for="(item, index) in statusList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-input
            :placeholder="$t('pleaseEnterName')"
            v-model="search.name"
            size="small"
            class="input"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-input
            :placeholder="$t('pleaseselectbusinesscontent')"
            v-model="search.base"
            size="small"
            class="input"
            clearable
          ></el-input>
        </el-col>

        <el-col :span="8">
          <div class="fr">
            <el-button size="small" type="primary" @click="searchChange">{{
              $t("search")
            }}</el-button>
            <el-button size="small " @click="resetChange">{{
              $t("reset")
            }}</el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="tableBlock tableData">
      <el-table
        :data="tableData"
        style="width: 100%; height: 550px"
        :tableLoading="loading"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
      >
        <el-table-column :label="$t('name')">
          <template slot-scope="scope">
            <div class="clearfix">
              <div class="fl">
                {{
                  scope.row.user_name_en
                    | priorFormat(scope.row.user_name_zh, LOCALE)
                }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('phone')">
          <template slot-scope="scope">
            <div class="salary">
              <span> {{ scope.row.mobile }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="简历" width="200px">
          <template slot-scope="scope">
            <div class="jl" @click="changeStatus(scope.row)">
             
              <i class="el-icon-download"></i>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('deliveryTime')">
          <template slot-scope="scope">
            <span>{{
              scope.row.created_time | secondFormat("LL") | textFormat
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('jobSearch')">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('resumeStatus')">
          <template slot-scope="scope">
            <span>{{
              scope.row.status == 0 ? $t("unread") : $t("haveRead")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('tags')">
          <template slot-scope="scope">
            <span>{{ scope.row.remarks || "-" }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="address" :label="$t('handle')" width="210">
          <template slot-scope="scope">
            <orderTalk
              v-if="
                PJSource == 13 ||
                PJSource === 12 ||
                PJSource === 7 ||
                PJSource === 26 ||
                PJSource === 16 ||
                PJSource === 14 ||
                PJSource === 18 ||
                PJSource === 27 ||
                PJSource === 29 ||
                PJSource === 30 ||
                PJSource === 33 ||
                PJSource === 34 ||
                PJSource === 45
              "
              :in_userId="scope.row.user_id"
              :position_id="scope.row.pisition_id"
              @successChange="getList"
              invitationType="2"
            >
              <el-button type="text" slot="btn" style="padding-right: 10px">{{
                $t("InvitationInterview")
              }}</el-button>
            </orderTalk>
            <el-button
              type="text"
              style="padding-right: 10px"
              @click="getMyresume(scope.row)"
              >{{ $t("lookResume") }}</el-button
            >
            <el-button type="text" @click="editBase(scope.row)">{{
              $t("addTags")
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :page-size="limit"
        :current-page.sync="start"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <el-dialog
      :title="$t('resumeDetails')"
      :visible.sync="previewResumeDialog"
      width="800px"
    >
      <myInfomation
        v-if="previewResumeDialog"
        :resumeInfo="showform"
      ></myInfomation>
    </el-dialog>
  </div>
</template>
<script>
import { SSstore } from "~/baseUtils/storage";
import orderTalk from "~bac/components/button/orderTalk";
import myInfomation from "~bac/components/talent/myInfomation";

export default {
  layout: "memberCenter",
  components: { orderTalk, myInfomation },
  data() {
    return {
      loading: false,
      btnLoading: false,
      previewResumeDialog: false,
      total: 0,
      limit: 10,
      start: 1,
      tableHeight: 100,
      jobList: [],
      showform: {},
      statusList: [
        {
          label: this.$t("unread"),
          value: 0,
        },
        {
          label: this.$t("haveRead"),
          value: 1,
        },
      ],
      search: {
        name: "",
        status: null,
        base: "",
        job: "",
      },
      tableData: [],
    };
  },
  mounted() {
    this.getjobList();
  },
  methods: {
    editBase(e) {
      this.$prompt(this.$t("addTabs!"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("Confirm"),
        inputPlaceholder: this.$t("labelUpToTen"),
        cancelButtonText: this.$t("cancel"),
        inputValidator: (value) => {
          if (value.length > 10) {
            //对输入内容校验

            return $t("tagsMaxTen");
          }
        },
      })
        .then(async ({ value }) => {
          let data = await this.$store.dispatch("baseConsole/changeStatus", {
            job_position_apply_id: e.job_position_apply_id,
            remarks: value,
            // source:this.PJSource
          });
          if (data.success) {
            this.getList();
          }
        })
        .catch(() => {});
    },
    resetChange() {
      this.search = {
        name: "",
        status: null,
        base: "",
        job: "",
      };
    },
    // 简历详情
    async getMyresume(item) {
      if (!item.user_id) return;
      let data = await this.$store.dispatch(
        "baseConsole/talent_user_getMyresume",
        {
          user_id: item.user_id,
        }
      );
      let info = data.data;
      console.log("info.user_info.birthday", info);
      if (info.user_info) {
        info.user_info.birthday = this.$moment(
          info.user_info.birthday * 1000
        ).format("YYYY-MM-DD");
      }

      info.work_experience_info &&
        info.work_experience_info.forEach((ee, vv) => {
          if (ee.position_type == 0) {
            ee.position_type = "";
          }
          if (ee.start_time) {
            ee.times = [];
            ee.times[0] = new Date(
              this.$moment(ee.start_time * 1000).format("YYYY-MM-DD")
            );
            ee.times[1] = new Date(
              this.$moment(ee.end_time * 1000).format("YYYY-MM-DD")
            );
          } else {
            ee.times = "";
          }
        });
      info.educational_experience_info &&
        info.educational_experience_info.forEach((ee, vv) => {
          if (ee.level == 0) {
            ee.level = "";
          }
          if (ee.class_type == 0) {
            ee.class_type = "";
          }
          if (ee.start_time) {
            ee.times = [];
            ee.times[0] = new Date(
              this.$moment(ee.start_time * 1000).format("YYYY-MM-DD")
            );
            ee.times[1] = new Date(
              this.$moment(ee.end_time * 1000).format("YYYY-MM-DD")
            );
          } else {
            ee.times = "";
          }
        });
      console.log("info.user_info.birthday", info.user_info.birthday);
      info.user_info.birthday = this.$moment(info.user_info.birthday).unix();
      this.showform = info;
      this.previewResumeDialog = true;
      if (item.status === 0) {
        this.changeStatus(item);
      }
    },
    searchChange() {
      this.start = 1;
      this.getList();
    },
    handlePageChange(e) {
      this.start = e;
      this.getList();
    },
    async changeStatus(item) {
      if (item.status === 0) {
        let data = await this.$store.dispatch("baseConsole/changeStatus", {
          job_position_apply_id: item.job_position_apply_id,
          status: 1,
        });
        if (data.success) {
          // this.getList();
          item.status = 1;
        }
      }
    },
    async getList(val) {
      if (!this.USER_INFO.company_id) {
        return;
      }
      this.loading = true;
      let params = {
        company_id: this.USER_INFO.company_id,
        limit: this.limit,
        source: this.PJSource,
        start: this.start - 1,
        nsbc_status: this.search.status,
        remarks: this.search.base,
        name: this.search.name,
        position_id: this.search.job,
      };
      //   let _params = Object.assign(params, val);
      let data = await this.$store.dispatch(
        "baseConsole/getResumeList",
        params
      );
      if (data.success) {
        this.loading = false;
        this.total = data.count;
        this.tableData = data.data;
      } else {
        this.loading = false;
      }
    },
    async getjobList(val) {
      let params = {
        company_id: this.USER_INFO.company_id,
        source: this.PJSource,
        nopage: 1,
      };
      let _params = Object.assign(params, val);
      let data = await this.$store.dispatch(
        "baseConsole/job_positionList",
        _params
      );
      if (data.success) {
        console.log("fffff", data.data);
        this.jobList = data.data;

        if (SSstore.get("positionID")) {
          this.search.job = Number(SSstore.get("positionID"));
          SSstore.remove("positionID");
        }
        this.getList();
      } else {
      }
    },
  },
};
</script>
<style lang="less" scoped>
.search {
  padding: 30px 15px;
  .input {
    width: 90%;
  }
}
.jl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    display: block;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
